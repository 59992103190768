body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiBottomNavigation-root {
  height: calc(56px + env(safe-area-inset-bottom)) !important;
}

pwa-install {
  --install-button-color: #250188 !important;
}

pwa-install #openButton {
  background: green !important;
}
